
import { Movimentacao } from '@/core/models/almoxarifado';
import { PageBase } from '@/core/models/shared';
import { MovimentacaoService } from '@/core/services/almoxarifado';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioMovimentacao extends PageBase { 

    service = new MovimentacaoService();
    item: Movimentacao = new Movimentacao();
    overlay: boolean = false;

    mounted() {  
        const filter = this.$route.query;
        this.overlay = true;
        this.service.ListarComFiltro(filter, 'EmpreendimentoConsumidor, AlmoxarifadoItem.Produto.Tipo, AlmoxarifadoItem.Produto, AlmoxarifadoItem.Almoxarifado.Empreendimento, Tipo, TipoOrigem')
            .then(
                res=>{
                    this.item = res.data.items;
                },
                err=>{
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{
                this.overlay = false;
            });
    }
}
